<template>
    <div class="login animate__animated animate__fadeInDown">
        <a-form>
        <a-form-item>
            <span class="label" slot="label">{{$t('login.label.account')}}</span>
            <a-input class="bg-sec" size="large" v-model="account" ref="account" />
        </a-form-item>
        <a-form-item>
            <span class="label" slot="label">{{$t('login.label.password')}}</span>
            <a-input-password id="password" class="bg-sec" size="large" autocomplete="off" ref="password" v-model="password" />
        </a-form-item>
        <div class="btn-primary mg30" @click="submit">
            {{$t('common.login_btn')}}
        </div>
        <div class="flex color-gray">
            <span @click="forgetPass">{{$t('login.forget')}}</span>
            <span @click="goRegister">{{$t('common.register_btn')}}</span>
        </div>
        </a-form>
    </div>
</template>
<script>
// import config from "../config";
export default {
    name: 'Login',
    data(){
        return {
            account: '',
            password: '',
            loading: false,
        }
    },
    components:{
    },
    mounted(){
        this.$refs.account.focus();
        this.$store.commit('setShowNav', false);
        this.$store.commit('setShowLogo', true);
    },
    methods:{
        submit(){
            this.loading = true;
            let _this = this;
            if(!this.account || !this.password){
                this.$message.error(this.$t('login.tips.iptError'));
                return;
            }
            let data = {
                account: this.account,
                password: this.password
            };
            this.$http.call(this.$http.api.login,{data:data}).then(function(res){
                let resp = res.data;
                if(resp.code=='200'){
                    _this.$store.commit('setToken', resp.data.token);
                    _this.$store.commit('setUserinfo', resp.data.user);
                    _this.$router.push({name:'home'});
                }else{
                    _this.$message.error(resp.data);
                } 
            },function(res){
                console.log(res.message);
                _this.$message.error(_this.$t('login.tips.accountorpasswordwrong'));
            }).then(()=>{
                _this.loading = false
            });
        },
        goRegister(){
            this.$router.push({name:'register'});
        },
        forgetPass(){
            this.$message.info(this.$t('login.tips.forget'));
        },
    }
}
</script>
<style lang="less">
.login{
    width: 100%;
    padding: 2rem;

}


</style>
